<template>
    <div class="excel-box">
        <el-upload
            class="upload-demo"
            :action="url"
            :on-success="onSuccess"
            :data="form"
            :on-error="errorInfo"
            :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">{{helpInfo}}</div>
        </el-upload>
    </div>
</template>
<script>
import {resetJsonBy} from '@/utils/util.js'
import {exportExcel} from '@/utils/excel.js'
export default {
    data(){
        return{
             url:this.API.api,
            fileList:[],
            dataList:[],//读取到的数据表
            list:[],//分页数据
            totalPage:0,
            msg:'',
            helpInfo:"请使用模板填入数据后导入，不超过1万条数据",
            form:{
                method:"importExcel"
            },
            user:{},
        }
    },
    created(){
        this.user = JSON.parse(sessionStorage.getItem('user'))
        this.url=this.url+'?token='+this.user.token
        console.log(this.url)
    },
    methods:{
        errorInfo(response){
            console.log(response)
            this.$message({
                    message: response.msg||'上传失败！',
                    type: 'warning'
                });
        },
        onSuccess(response){
            if(response.status==0&&response.data){
                //获取到数据
                this.$message({
                    message: response.msg,
                    type: 'success'
                });
                console.log("data",response.data)
                this.msg = response.msg;
                this.dataList = response.data 
                let array = resetJsonBy(this.dataList,'num')
                let mList=[]
                array.forEach((item)=>{
                    let count=item.data.length,
                    dateStr = "",OM='',OC=''
                    ,cockroach='',risk='',
                    fly='',inspection='',name='',rat='',
                    rectification='',staff='',summary=''
                    item.data.forEach((it)=>{
                        risk=""
                        //i!=(count-1)?dateStr +=",":''
                        let mday
                        if(it.date){
                            mday = it.date.split("/")[2]
                            dateStr +=mday+'号，'
                        }
                        OM = it.OM 
                        OC = it.OC 
                        //蟑螂活体
                        if(it.cockroach){
                            cockroach += mday+'号发现活体'+it.cockroach+'只,'
                        }
                        if(it.cockroach_dead){
                            if(!cockroach){
                                cockroach+= mday+'号'
                            }
                            cockroach+= '发现尸体'+it.cockroach_dead+'只，'
                        }
                        if(it.cockroach_risk=="R"){
                            risk+="蟑螂高风险，"
                        }
                        if(it.fly_risk=="R"){
                            risk+="飞虫高风险，"
                        }
                        if(it.fly){
                            fly+= mday+'号发现活体'+it.fly+'只,'
                        }
                        if(it.rat_risk=="R"){
                            risk+="鼠患"
                        }
                        if(it.rat){
                            rat += mday+'号捕获'+it.rat+'只，'
                        }
                        inspection+=mday+'号全面检查：'+it.inspection+'。'
                        name = it.name
                        rectification +=mday+'号整改建议：'+it.rectification+'。'
                        if(staff&&staff!=it.staff){
                            staff+=it.staff
                        }else{
                            staff=it.staff
                        }
                        summary +=mday+'号服务总结：'+it.summary+'；'
                    })
                     mList.push({
                         name,
                         inspection,
                           num:item.num,
                           date:dateStr,
                           times:count,
                           cockroach,
                            OC,
                            OM,
                            risk:risk?'高风险':'低风险',
                            risk_type:risk,
                            rat,
                            fly,
                            staff,
                            summary,
                            rectification

                        })
                })
                this.list = mList
                console.log(this.list)
                this.download()
            }else{
                //失败了
                this.$message({
                    message: response.msg||'上传失败！',
                    type: 'warning'
                });
            }
        },
        download(){
            let columnTitle=['餐厅编号','OM','OC','名称','服务日期','服务次数','风险评定','风险类别','蟑螂迹象','老鼠迹象','飞虫迹象','全面检查','改善建议','服务总结']
                let columnData = []
                this.loading = false
                this.list.forEach(it=>{
                        columnData.push([it.num,it.OM,it.OC,it.name,it.date,it.times,it.risk,it.risk_type,it.cockroach,it.rat,it.fly,it.inspection,it.rectification,it.summary])
                 })
                exportExcel(columnTitle, columnData, "9月数据报表导出明细");
        }
    }
}
</script>